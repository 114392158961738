import React, { useEffect, useState } from 'react'
import DetailsPageSlider from '../../../components/DetailsPageSlider/DetailsPageSlider'
import ListingDetails from '../../../components/ListingDetails/ListingDetails'
import VendorInfo from '../../../components/VendorInfo/VendorInfo'
import Ads from '../../../components/Ads/Ads'
import { getListingById } from '../../../api/services/listing.service'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/Loader/Loader'
import './ListingDetailsPage.scss'
import noImg from '../../../assets/images/no-image.png'
import {
    addFavourite,
    removeFavourite,
} from '../../../api/services/favourites.service'

const ListingDetailsPage = () => {
    const { id } = useParams()
    const [listingData, setLsitingData] = useState<any>([])
    const [vendorInfo, setVendorInfo] = useState<any>([])
    const [favValue, setFavVal] = useState<any>()
    const [loading, isLoading] = useState(false)
    const dataString = localStorage.getItem('email')
    let email = ''
    if (dataString) {
        email = JSON.parse(dataString)
    }
    const userStr = localStorage.getItem('user')
    const navigate = useNavigate()

    const payload = {
        userid: email,
        listingid: id,
    }

    const getListingDetails = () => {
        isLoading(true)
        getListingById(id, "includeDetails")
            .then((response) => {
                setLsitingData(response.data.data[0])
                setVendorInfo(response.data.userDetail)
                setFavVal(response.data.isAlreadyFavorite)
                isLoading(false)
            })
            .catch((error) => {
                console.log({ error })
                isLoading(false)
            })
    }

    const handleAddFavourite = () => {
        if (userStr) {
            addFavourite(payload)
                .then((response) => {
                    if (response?.status === 200) {
                        getListingDetails()
                    }
                })
                .catch((error) => console.log(error))
        } else {
            navigate(`/login`)
        }
    }

    const handleRemoveFavourite = () => {
        removeFavourite(payload)
            .then((response) => {
                if (response?.status === 200) {
                    getListingDetails()
                }
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getListingDetails()
    }, [])

    if (loading) {
        return <Loader />
    }

    return (
        <div className="limbo-details-section ">
            <div className="details-section">
                <div className="imgPanel">
                    <h2>
                        {listingData?.listingName}{' '}
                        <small>({listingData.listingType})</small>
                    </h2>
                    {listingData.images?.length > 0 ? (
                        <DetailsPageSlider data={listingData?.images} />
                    ) : (
                        <img src={noImg} alt="img" />
                    )}
                </div>

                {listingData && (
                    <ListingDetails
                        listingData={listingData}
                        id={id}
                        favValue={favValue}
                        AddFavourite={handleAddFavourite}
                        RemoveFavourite={handleRemoveFavourite}
                    />
                )}
            </div>
            <VendorInfo data={vendorInfo} />
            <div className="ads">
                <Ads slot="ListingPageBanner" />
            </div>
        </div>
    )
}

export default ListingDetailsPage
